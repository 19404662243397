#contact-us {
	margin-top: 12px;
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	#contact-options {
		display: grid;
		width: 100%;
		grid-template-columns: 33% 33% 33%;

		.contact-option {
			text-align: center;

			&:first-of-type {
				text-align: left;
			}

			&:last-of-type {
				text-align: right;
			}
		}

		@media screen and (max-width: 480px) {
			grid-template-columns: 100%;
			.contact-option {
				text-align: center !important;
				padding: 30px 0px;
			}
		}
	}

	address,
	.company-number,
	.social-option {
		font-style: normal;
		font-size: 10pt;
		line-height: 16pt;
		color: #616161;

		@media (prefers-color-scheme: dark) {
			color: #aaa;
		}

		a {
			color: #616161;
			text-decoration: none;

			@media (prefers-color-scheme: dark) {
				color: #aaa;
			}
		}
	}

	.social-option {
		display: flex;
		justify-content: flex-end;

		@media screen and (max-width: 480px) {
			justify-content: center;
			padding: 10px;
		}

		img {
			margin-left: 10px;
			@media (prefers-color-scheme: dark) {
				filter: invert(0.7);
			}
		}
	}

	.social-option a {
		display: flex;
		width: 75px;
		align-items: center;
		justify-content: flex-end;
		padding: 4px 0px;

		@media screen and (max-width: 480px) {
			justify-content: flex-start;
			width: auto;
		}
	}

	.company-number {
		a {
			color: #616161;
			font-weight: 500;
			text-decoration: none;

			@media (prefers-color-scheme: dark) {
				color: #aaa;
			}
		}
		padding-top: 20px;
	}
}
